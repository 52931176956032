
import { Options, Vue } from 'vue-class-component';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import {
  BaseSelectDropdown,
  SidebarNavigation,
  SidebarNavigationGroup,
  SidebarNavigationLink
} from '@/lib/components';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';
import { Customer, CustomerBranding } from '@/models';
import { arrayUnique } from '@/helpers/array.helper';
import { IOption } from '@/lib';

@Options({
  components: {
    DefaultLayout,
    SidebarNavigation,
    SidebarNavigationGroup,
    SidebarNavigationLink,
    BaseSelectDropdown
  },
  props: {
    showLayout: {
      type: Boolean,
      default: true
    },
    routePrefix: {
      type: String,
      default: ''
    },
    organisationId: {
      type: String,
      default: null
    },
    region: {
      type: String,
      default: null
    }
  }
})
export default class LegalPage extends Vue {
  routePrefix!: string;
  organisationId!: string | null;
  region!: string | null;
  countryCode: string | null = null;
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  get customers(): Customer[] {
    return this.isDomainAdmin ? this.sessionStore.adminCustomers : [];
  }

  get branding(): CustomerBranding[] {
    return this.isDomainAdmin ? this.customers.map(({ branding }) => branding) : [this.sessionStore.customerBranding];
  }

  get allLegalRegions(): string[] {
    return this.isDomainAdmin ? arrayUnique(this.customers.map(({ legal_regions }) => legal_regions).flat()) :
      this.sessionStore.legalRegions;
  }

  get legalRegionsDropdownOptions(): IOption[] {
    return this.allLegalRegions.map((region) => ({
      value: region,
      label: this.$t(`platform.countries.${region.toUpperCase()}`)
    })).sort((a, b) => (a.label > b.label) ? 1 : -1);
  }

  get isDomainAdmin() {
    return (this.sessionStore.currentUser.is_admin && this.sessionStore.currentOrganisationId === null);
  }

  async mounted() {
    if (this.region) {
      await this.goToDefaultRoute(this.region, this.organisationId);
    } else {
      await this.goToDefaultRoute(this.allLegalRegions[0], this.organisationId);
    }

    const unwatchRoute = this.$watch('$route', async (to, from) => {
      if (this.$route.name.includes(`${this.routePrefix}legal`)) {
        if (from.path === to.path && to.hash) {
          // Ignore as this is just clicking on anchor link
          return;
        }
        if (to.params.organisationId && to.params.organisationId !== from.params.organisationId) {
          await this.goToDefaultRoute(from.params.region ?? this.allLegalRegions[0], to.params.organisationId);
        }
        if (!to.params.region) {
          await this.goToDefaultRoute(this.allLegalRegions[0], from.params.organisationId);
        }
      } else {
        unwatchRoute();
      }
    });
  }

  async goToDefaultRoute(region: string, organisationId: string) {
    let routeName = `${this.routePrefix}legal-privacy-policy`;
    if (this.$route.name?.includes('legal-privacy-policy') || this.$route.name?.includes('legal-terms-service')) {
      routeName = this.$route.name;
    }
    await this.$router.push({
      name: routeName,
      params: {
        region,
        organisationId
      }
    });
  }
}
